<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
  show_scoped_users: {
    type: Boolean,
    default: true,
  },
  show_scoped_teams: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Object,
  },
  multi: {
    type: Boolean,
    default: false,
  },
  tags_removable: {
    type: Boolean,
    default: true,
  },
  format_load: {
    type: Boolean,
    default: false,
  },
  truncate_tag_length: {
    type: Number,
    default: null,
  },
  has_custom_options: {
    type: Boolean,
    default: false,
  },
  display_other_items: {
    type: Boolean,
    default: true,
  },
  custom_options: {
    type: Array,
    default: () => [],
  },
  callbacks: {
    type: Object,
    default: () => ({
      c: item => true,
    }),
  },
});

const emit = defineEmits(['open', 'change']);
const route = useRoute();
const common_store = useCommonStore();

const list_data = computed(() => {
  if (!props.display_other_items)
    return [];

  const asset_id = props?.asset_id ? props.asset_id : route.params.asset_id;
  // Using scoped members
  let member_items = [];
  let team_items = [];
  if (props.has_custom_options) {
    member_items = props.custom_options?.length ? common_store.scope_users(asset_id).filter(item => props.custom_options.includes(item.uid)) : [];
    team_items = props.custom_options?.length ? common_store.scope_teams(asset_id).filter(item => props.custom_options.includes(item.uid)) : [];
  }

  else {
    if (props.show_scoped_users)
      member_items = common_store.scope_users(asset_id).filter(item => (props?.callbacks?.user_filter ? props.callbacks.user_filter(item) : true));
    else member_items = common_store.users.filter(item => (props?.callbacks?.user_filter ? props.callbacks.user_filter(item) : true));

    if (props.show_scoped_teams)
      team_items = common_store.scope_teams(asset_id);
    else
      team_items = common_store.teams;
  }
  member_items = member_items.filter(item => item.status !== 'deprovisioned');
  let members = member_items?.map((user) => {
    return {
      name: user.first_name ? `${user.first_name} ${user.last_name || ''}` : user.email,
      uid: user.uid,
      member: true,
    };
  });
  let teams = team_items?.map((team) => {
    return {
      name: team.name,
      uid: team.uid,
      member: false,
    };
  });
  if (props?.options?.existing_users)
    members = members.filter(item => !props.options.existing_users.includes(item.uid));

  if (props?.options?.existing_teams)
    teams = teams.filter(item => !props.options.existing_teams.includes(item.uid));

  if (props?.options?.owner_uid)
    members = members.filter(item => props.options.owner_uid !== item.uid);
  return props.options?.has_teams
    ? [
        {
          label: 'Members',
          items: members,
        },
        {
          label: 'Teams',
          items: teams,
        },

      ]
    : members;
});

function getUID(item) {
  // Check the user is an object or string
  if (typeof item === 'object')
    return item.uid;

  return item;
}

function formatAssigneeData(uids) {
  if (common_store.users.length) {
    const asset_id = props?.asset_id ? props.asset_id : route.params.asset_id;
    const users = [
      ...(common_store.scope_users(asset_id)?.map((member) => {
        return {
          name: member.first_name ? `${member.first_name} ${member.last_name || ''}` : member.email,
          uid: member.uid,
          member: true,
        };
      }) || []),
      ...(common_store.scope_teams(asset_id)?.map((team) => {
        return { ...team, member: false };
      }) || []),
    ];
    const filtered_users = users.filter(user => uids?.includes(user.uid));
    if (props.multi)
      return filtered_users;
    else
      return Array.isArray(filtered_users) ? filtered_users[0] : filtered_users;
  }
  else { return uids; }
}
</script>

<template>
  <TagsElement
    v-if="multi"
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': false,
      'groups': options?.has_teams,
      'group-hide-empty': options?.has_teams,
      'append-new-option': false,
      'group-select': false,
      'object': true,
      'create': !!props.options?.allow_create,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': list_data,
      ...(props.format_load && { 'format-load': formatAssigneeData }),
      ...options,
    }"
    @open="emit('open', $event)"
    @change="emit('change', $event)"
  >
    <!-- values tags -->
    <template #tag="{ option, handleTagRemove }">
      <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
        <div v-if="props.options?.allow_create && option?.uid && option?.name && (option.uid === option.name)">
          {{ option.name }}
        </div>
        <HawkMembers
          v-else
          :members="getUID(option)"
          size="tiny"
          type="label"
          v-bind="{ ...(truncate_tag_length ? { name_truncate_length: truncate_tag_length } : {}) }"
        />
        <div v-if="tags_removable" class="hover:bg-gray-100 rounded" @mousedown.prevent="handleTagRemove(option, $event)">
          <IconHawkX v-if="tags_removable" class="text-[17px] text-gray-400 hover:text-gray-600" />
        </div>
      </div>
    </template>
    <!-- dropdown options -->
    <template #option="{ option }">
      <div v-if="props.options?.allow_create && option?.uid && option?.name && (option.uid === option.name)">
        {{ option.name }}
      </div>
      <HawkMembers
        v-else
        :members="getUID(option)"
        size="xs"
        type="label"
        name_classes="!text-gray-700 !w-auto !truncate !inline-block !text-sm"
      />
    </template>

    <template #after>
      <slot name="after" />
    </template>
    <template #description>
      <slot name="description" />
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template #info>
      <slot name="info" />
    </template>
  </TagsElement>
  <SelectElement
    v-else
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': true,
      'groups': options.has_teams,
      'group-hide-empty': options.has_teams,
      'hide-selected': true,
      'object': true,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': list_data,
      ...(props.format_load && { 'format-load': formatAssigneeData }),
      ...options,
    }"
    @open="emit('open', $event)"
    @change="emit('change', $event)"
  >
    <!-- selected value -->
    <template #single-label="{ value }">
      <div class="flex items-center h-full absolute left-0 pl-3">
        <HawkMembers
          :members="getUID(value)"
          size="badge"
          type="label"
          v-bind="{ ...(truncate_tag_length ? { name_truncate_length: truncate_tag_length } : {}) }"
        />
      </div>
    </template>
    <!-- dropdown options -->
    <template #option="{ option }">
      <HawkMembers
        :members="getUID(option)"
        size="badge"
        type="label"
      />
    </template>

    <template #after>
      <slot name="after" />
    </template>
    <template #info>
      <slot name="info" />
    </template>
  </SelectElement>
</template>

<style lang="scss" scoped>
:deep([type='text']:focus) {
  box-shadow: none;
}

:deep(.focused-hover\:form-shadow-input-hover.form-focus:hover) {
  border: 1px solid #84CAFF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E9FF;
}

:deep(.outline-zero) {
  outline: none;
}
</style>

<script setup>
import draggable from 'vuedraggable';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  available_fields: {
    type: Array,
    required: true,
    default: () => [],
  },
  label_key: {
    type: String,
    required: true,
  },
  value_key: {
    type: String,
    required: true,
  },
  children_key: {
    type: String,
    required: true,
  },
  selected_available_fields: {
    type: Array,
    required: true,
    default: () => [],
  },
  active_groups: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  level: {
    type: Number,
    required: true,
    default: 0,
  },
});

const emit = defineEmits(['activateGroup', 'reject']);
const emitter = useEmitter();

function toggleGroupVisibility(group_value) {
  emitter.emit('active_groups_changed', {
    type: props?.active_groups?.[props.level]?.includes(group_value) ? 'remove' : 'add',
    level: props.level,
    value: group_value,
  });
}

function getLeafElements(current_element, first = true) {
  const leaf_elements = [];
  if (current_element?.[props.children_key])
    for (const child of current_element[props.children_key])
      if (child[props.children_key])
        leaf_elements.push(...getLeafElements(child, false));
      else
        leaf_elements.push(child);
  else if (!first)
    leaf_elements.push(current_element);

  return leaf_elements;
}

function onChange(event) {
  if (!event?.added?.element?._added)
    emit('reject', event);
}
</script>

<template>
  <draggable
    tag="div"
    :group="{ name: 'columns', pull: 'clone' }"
    :list="props.available_fields"
    :item-key="props.value_key"
    draggable=".is_draggable_group"
    @change="onChange"
  >
    <template #item="{ element: field_group }">
      <div
        class="is_draggable_group"
      >
        <div
          v-if="field_group?.[children_key]"
          @click="toggleGroupVisibility(field_group[value_key])"
        >
          <div
            v-if="getLeafElements(field_group)?.length"
            class="flex items-center h-8 cursor-pointer"
          >
            <slot
              name="parent_item"
              :label="field_group?.[label_key]"
              :is_active="props.active_groups[props.level]?.includes(field_group[value_key])"
              :item="field_group"
            />
          </div>
        </div>
        <div
          v-else
          @click="$event => emitter.emit('selection_add', { event: $event, field: field_group })"
        >
          <slot
            name="item"
            :label="field_group?.[label_key]"
            :is_selected="props.selected_available_fields.find(col => col[value_key] === field_group[value_key])"
            :item="field_group"
          >
            {{ field_group?.[label_key] }}
          </slot>
        </div>
        <div v-if="props.active_groups[props.level]?.includes(field_group[value_key])">
          <draggable
            :list="field_group[children_key]"
            :group="{ name: 'columns', pull: 'clone' }"
            :item-key="props.value_key"
            @change="onChange"
          >
            <template #item="{ element: field }">
              <div
                v-if="field?.[label_key] && !field?.[children_key]"
                @click="$event => emitter.emit('selection_add', { event: $event, field })"
              >
                <slot
                  name="item"
                  :label="field?.[label_key]"
                  :is_selected="props.selected_available_fields.find(col => col[value_key] === field[value_key])"
                  :item="field"
                >
                  {{ field?.[label_key] }}
                </slot>
              </div>
              <FieldsSelectorLeftSection
                v-else-if="field?.[children_key]?.length"
                :available_fields="[field]"
                :label_key="label_key"
                :value_key="props.value_key"
                :children_key="props.children_key"
                :selected_available_fields="props.selected_available_fields"
                :active_groups="props.active_groups"
                :level="props.level + 1"
                class="pl-8"
                @reject="onChange"
              >
                <template #item="{ label, is_selected, item }">
                  <slot name="item" :label="label" :is_selected="is_selected" :item="item" />
                </template>
                <template #parent_item="{ label, is_active, item }">
                  <slot name="parent_item" :label="label" :is_active="is_active" :item="item" />
                </template>
              </FieldsSelectorLeftSection>
            </template>
          </draggable>
        </div>
      </div>
    </template>
  </draggable>
</template>

import { acceptHMRUpdate, defineStore } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';

export function useChatStore() {
  return defineStore('chat', {
    state: () => ({
      chat: null,
      chat_channel: null,
      is_initializing: false,
      is_fetching_comments_data: false,
      active_editor_uuid: null,
      comments: [],
    }),
    actions: {
      async initialize() {
        if (this.chat || this.is_initializing)
          return;
        this.is_initializing = true;
        try {
          const res = await this.$services.common.getAll(
            { url: `${import.meta.env.VITE_APP_CORE_API_HOST}/stream/user-token/chat` },
            false,
          );
          const { StreamChat } = await import('stream-chat');
          const chat_client = StreamChat.getInstance(res.data.api_key);
          chat_client.setBaseURL('https://chat.stream-io-api.com');
          await this.set_stream_chat({
            client: chat_client,
            token: res.data.token,
          });
        }
        catch (error) {
          logger.error(error);
        }
        finally {
          this.is_initializing = false;
        }
      },
      async set_stream_chat(value) {
        this.chat = value;
        const auth_store = useAuthStore();
        const user = auth_store?.logged_in_user_details?.user_id;
        function fullName(user) {
          return user?.firstname
            ? `${user.firstname} ${user.lastname}`
            : user?.email;
        }

        try {
          await this.chat.client.connectUser(
            {
              id: user,
              name: fullName(auth_store?.logged_in_user_details),
              organization: { uid: auth_store.current_organization?.uid },
            },
            this.chat.token,
          );
          return true;
        }
        catch (e) {
          logger.error('chatstream error', e);
          return false;
        }
      },
      async set_data(channel_name, resource) {
        this.is_fetching_comments_data = true;
        const auth_store = useAuthStore();
        const client = this.chat?.client;
        if (client?.channel) {
          this.chat_channel = client.channel(
            'messaging',
            channel_name,
            { source_detail: { ...(resource || {}), asset_uid: this.$router?.currentRoute?.value?.params?.asset_id, organization_uid: auth_store.current_organization?.uid } },
          );

          await this.chat_channel.create();
        }

        await this.chat_channel.watch();
        this.comments = this.chat_channel.state.messages;
        this.chat_channel.on('message.new', this.updateComments);
        this.chat_channel.on('message.deleted', this.updateComments);
        this.chat_channel.on('message.updated', this.updateComments);
        this.chat_channel.on('reaction.new', this.updateComments);
        this.chat_channel.on('reaction.deleted', this.updateComments);
        this.is_fetching_comments_data = false;
      },
      async sendMessage(payload) {
        try {
          return await this.chat_channel.sendMessage(payload);
        }
        catch (error) {
          this.$toast({ text: 'Failed to send message', type: 'error' });
          return null;
        }
      },
      async updateMessage(payload) {
        try {
          return await this.chat.client.updateMessage(payload);
        }
        catch (error) {
          this.$toast({ text: 'Failed to update message', type: 'error' });
          return null;
        }
      },
      async fetch_most_recent_comments_in_pm(all_channels) {
        let all_messages = [];
        const all_channel_uids = all_channels.map(item => `pm-${item.uid}`);
        const channels = await this.chat?.client?.queryChannels?.({ id: { $in: all_channel_uids } });
        if (Array.isArray(channels))
          for (const channel of channels) {
            await channel.watch();
            const { messages } = await channel.query({ messages: { limit: 1 } });
            if (messages.length > 0) {
              const current_channel = all_channels.find(item => item.uid === channel.id.replace('pm-', ''));
              all_messages = all_messages.concat({
                uid: current_channel.uid,
                id: current_channel.id,
                message_info: messages[0],
              });
            }
          }
        return all_messages;
      },
      updateComments() {
        this.comments = cloneDeep(this.chat_channel.state.messages);
      },
    },
  })();
}

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useChatStore, import.meta.hot));

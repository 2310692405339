import { acceptHMRUpdate, defineStore } from 'pinia';
import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';
import { isString } from 'lodash-es';
import { jwtDecode } from 'jwt-decode';
import { SplitFactory } from '@splitsoftware/splitio';
import IdleTracker from 'idle-tracker';
import { v4 as uuidv4 } from 'uuid';
import { idp_config, okta_config } from '~/common/utils/constants';
import { accessTokenCookie, clearAuthentication, currentOrganizationCookie, getHostName } from '~/common/utils/common.utils';
import { useCommonStore } from '~/common/stores/common.store';
import { setDefaultTimezone } from '~/common/utils/date.util';
import { usePusherStore } from '~/common/stores/pusher.store.js';

const auth_client = new OktaAuth(okta_config);
export const useAuthStore = defineStore('auth', {
  state: () => ({
    sign_up_details: {
      uid: null,
      formData: {},
    },
    plans: [],
    idle_tracker: null,
    currency_codes: {
      USD: {
        code: 'USD',
        sign: '$',
      },
      INR: {
        code: 'INR',
        sign: '₹',
      },
      AUD: {
        code: 'AUD',
        sign: '$',
      },
      AED: {
        code: 'AED',
        sign: 'AED',
      },
    },
    selected_plan: {},
    logged_in_user_details: null,
    signature: null,
    ip_address: null,
    split_client: null,
    is_one_signal_initialized: false,
    is_datadog_initialized: false,
  }),
  getters: {
    current_organization() {
      return this.logged_in_user_details?.organization;
    },
    current_organization_uid() {
      return this.logged_in_user_details?.organization?.uid;
    },
    logged_in_user_id() {
      return this.logged_in_user_details?.user_id;
    },
    access_token() {
      return accessTokenCookie();
    },
    check_permission() {
      return (permission_name, asset_id) => {
        return !!(this.logged_in_user_details?.permissions?.[permission_name]
          && (this.logged_in_user_details.permissions[permission_name].includes('*')
            || this.logged_in_user_details.permissions[permission_name].includes(asset_id || null)));
      };
    },
    check_split() {
      const checkSplitPerms = (treatment) => {
        return treatment === 'on' ? true : (treatment !== 'off');
      };

      return (split_name) => {
        const payload = {
          domain: getHostName(),
          organization_id: this.current_organization?.uid,
          ...(this.logged_in_user_details || {}),
        };

        payload.email = isString(payload.email) ? payload.email.toLowerCase() : 'null';

        if (this?.split_client?.getTreatment)
          return checkSplitPerms(this.split_client.getTreatment(split_name, payload));

        return false;
      };
    },
    is_hacker_user() {
      return this.logged_in_user_details?.user_role?.name === 'hacker';
    },
    is_manager_team_user() {
      return this.logged_in_user_details?.user_role?.name === 'manager_team';
    },
    is_internal_user() {
      return !!(this.logged_in_user_details?.user_role?.uid);
    },
    get_access_level() {
      if (this.logged_in_user_details?.is_owner)
        return 'Owner';
      if (this.logged_in_user_details?.is_manager)
        return 'Admin';
      if (this.logged_in_user_details?.user_type === 'guest')
        return 'Guest';
      if (this.is_internal_user)
        return 'Internal user';
      return 'User';
    },
    show_audit_logs() {
      return ['Owner', 'Admin', 'Internal user'].includes(this.get_access_level);
    },
  },
  actions: {

    load_split() {
      return new Promise((resolve, reject) => {
        try {
          let decodedJwt;
          if (accessTokenCookie())
            decodedJwt = jwtDecode(accessTokenCookie());
          // Instantiate the SDK. CDN will expose splitio globally
          const factory = SplitFactory({
            core: {
              authorizationKey: import.meta.env.VITE_APP_SPLIT_KEY,
              // your internal user id, or the account id that the user belongs to.
              // This could also be a cookie you generate for anonymous users
              key: decodedJwt
                ? decodedJwt.sub || decodedJwt.email || this.logged_in_user_details?.email
                : 'null',
              // an OPTIONAL traffic type, if provided will be
              // used for event tracking with the SDK client.
              trafficType: 'A_TRAFFIC_TYPE',
            },
          });
          // And get the client instance you'll use
          const client = factory.client();
          client.on(client.Event.SDK_READY, () => {
            this.split_client = client;
            this.split_client.setAttribute('tenant', import.meta.env.VITE_APP_TENANT || 'sensehawk');
            resolve(this.split_client);
          });
        }
        catch (error) {
          logger.error('SPLIT couldn\'t load');
          reject(new Error(error));
        }
      });
    },
    setup_idle_tracker() {
      function idleFunction(e) {
        if (e.idle)
          sessionStorage.setItem('segment_session_id', uuidv4());
      }
      this.idle_tracker = new IdleTracker({
        // 30 minutes timeout to generate a new session_id
        timeout: 1800000,
        onIdleCallback: idleFunction,
        throttle: 500,
      });

      this.idle_tracker.start();
    },
    saveAuthentication(payload) {
      accessTokenCookie('set', payload.access_token);
    },
    async update_signature(req) {
      const signature = this.signature;
      try {
        const auth_store = useAuthStore();
        this.signature = req.body.signature;
        return this.$services.users.post({
          toast: false,
          attribute: `${auth_store.logged_in_user_details?.user_id}/signature`,
          body: req.body,
          ...(req.query && { params: req.query }),
          ...(req.headers && { headers: req.headers }),
        });
      }
      catch (err) {
        this.signature = signature;
        logger.error(err);
      }
    },

    async set_signature() {
      try {
        if (this.signature)
          return this.signature;
        const auth_store = useAuthStore();
        const response = await this.$services.users.get({
          id: auth_store.logged_in_user_details?.user_id,
          attribute: 'signature',
          toast: false,
        });
        this.signature = response.data.signature;
      }
      catch (err) {
        logger.error(err);
      }
    },

    set_up_onesignal(logged_in_user_uid) {
      window.OneSignal.push(() => {
        window.OneSignal.setExternalUserId(logged_in_user_uid);
      });
      window.OneSignal.push(() => {
        window.OneSignal.on('notificationDisplay', (event) => {
          logger.info('OneSignal notification displayed: ', event);
        });
        window.OneSignal.on('notificationPermissionChange', (change) => {
          logger.log('%cnotificationPermissionChange: ', 'font-weight: bold;', change);
          const bool = change.to === 'granted';
          window.OneSignal.setSubscription(bool);
        });
      });
      window.OneSignal.push(() => {
        window.OneSignal.on('subscriptionChange', (value) => {
          if (value)
            window.OneSignal.push(() => {
              window.OneSignal.setExternalUserId(logged_in_user_uid);
            });
          else
            window.OneSignal.push(() => {
              window.OneSignal.removeExternalUserId();
            });
        });
      });
    },

    async set_logged_in_user_details(options = {}) {
      if (!this.logged_in_user_details || options.forceUpdate) {
        const response = await this.$services.auth.fetchLoggedInUserDetails();
        if (response)
          this.logged_in_user_details = response.data;
        try {
          if (this.logged_in_user_details) {
            currentOrganizationCookie('set', this.logged_in_user_details.organization?.uid || '');
            setDefaultTimezone(this.logged_in_user_details?.timezone);
            const pusherStore = usePusherStore();
            pusherStore.initialize();

            if (this.is_one_signal_initialized)
              this.set_up_onesignal(this.logged_in_user_details.user_id);

            if (!this.current_organization?.uid)
              this.$router.push({ name: '/' });
          }
        }
        catch (err) {
          logger.log(err);
        }
      }
    },
    async set_ip_address() {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        this.ip_address = response.data;
      }
      catch (e) {
        logger.log(e);
        return {};
      }
    },
    async sign_in({ email, password }) {
      try {
        const response = await auth_client.signInWithCredentials({ username: email, password });

        if (response.status === 'SUCCESS') {
          const tokens = await auth_client.token.getWithoutPrompt({ sessionToken: response.sessionToken });
          const { data } = await this.$services.auth.fetchAccessToken({ body: { code: tokens.code, redirect_uri: okta_config.redirectUri } });
          this.saveAuthentication(data);
          return { signed_in: true, response };
        }
        else {
          return { signed_in: false, response };
        }
      }
      catch (error) {
        logger.error(error.message);
        return { signed_in: false, response: error };
      }
    },
    async sign_out() {
      try {
        const commonStore = useCommonStore();
        // Not needed as sign page will clear anyways
        commonStore.app_loading = true;
        await this.$services.auth.logout();
        clearAuthentication();
        await auth_client.signOut({
          postLogoutRedirectUri: `${location.origin}/auth/sign-in`,
        });
      }
      catch (error) {
        logger.error('Error while logging out: ', error);
      }
    },
    async socialSignIn(from = 'sign-in') {
      const { sign_up_idp, login_idp } = idp_config;
      await auth_client.token.getWithRedirect({ idp: from === 'sign-up' ? sign_up_idp : login_idp });
    },
    is_maintenance_enabled() {
      return false;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));

<script setup>
import draggable from 'vuedraggable';

const props = defineProps({
  items: {
    type: Array,
    default: () => ([]),
  },
  menu_items: {
    type: Array,
    default: () => ([]),
  },
  max_limit: {
    type: Number,
    default: 10,
  },
  texts: {
    type: Object,
    default: () => ({}),
  },
  min_items_limit: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['openFilterPopup', 'onMove', 'handleConditionalFormatting', 'handleColumnConfiguration']);
</script>

<template>
  <div>
    <draggable
      :list="items"
      class="min-w-full"
      tag="div"
      draggable=".is_draggable"
      handle=".move"
      item-key="item"
      @change="emit('onMove', $event)"
    >
      <template #item="{ element: filter, index }">
        <div
          class="group flex items-center justify-between mb-4 px-2 text-sm is_draggable cursor-pointer w-min-full rounded-lg hover:bg-gray-100"
        >
          <div class="flex items-center text-sm cursor-pointer" @click="emit('openFilterPopup', filter)">
            <icon-hawk-drag-icon class="cursor-move move flex move text-lg text-gray-800 h-4 w-4 mr-1.5 invisible group-hover:visible" />
            <div class="text-[14px] font-medium pointer-events-auto">
              <HawkText :content="filter.name" :length="50" />
            </div>
          </div>
          <HawkMenu
            :items="[
              {
                id: 'edit',
                label: $t('Edit'),
                on_click: () => emit('openFilterPopup', filter),
              },
              {
                id: 'columns',
                label: $t('Columns'),
                on_click: () => emit('handleColumnConfiguration', filter),
              },
              {
                id: 'cf',
                label: $t('Conditional formatting'),
                on_click: () => emit('handleConditionalFormatting', filter),
              },
              ...(min_items_limit < items.length ? [{
                id: 'delete',
                label: $t('Delete'),
                on_click: () => emit('delete', { filter, index }),
              }] : []),
            ].filter(val => menu_items.includes(val.id))" position="bottom-left" additional_trigger_classes="!ring-0 !border-0"
          />
        </div>
      </template>
    </draggable>
    <div v-if="items.length < max_limit" class="flex items-center cursor-pointer p-2 text-[14px] font-semibold text-primary-700 mb-2" @click="emit('openFilterPopup', null)">
      <icon-hawk-plus class="mr-1" />
      {{ props.texts.create_cta }}
    </div>
  </div>
</template>
